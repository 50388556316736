<!--suppress XmlInvalidId -->
<template>
  <RolesCollection :organization="organization" />
</template>

<script>
export default {
  name: "OrganizationRoles",
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
}
</script>
